@import url("https://fonts.googleapis.com/css2?family=Dekko&display=swap");

html,
body {
  font-family: "Nunito", sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

html,
body,
#root > * {
  transition: background-color 200ms ease-in-out;
}
